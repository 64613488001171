<template>
  <section class="plr-sm">
    <div v-show="!showUserModal">
    <el-header class="y-center ptb-xs">
      <span class="size-md bolder">新增支部</span>
    </el-header>
    <el-card>
      <!-- 最新版 -->
      <el-form ref="form" :model="form" label-width="80px">
         <!-- 1.支部名称 -->
        <el-form-item label="支部名称">
          <el-input placeholder="请输入支部名称" v-model="form.name"></el-input>
        </el-form-item>
        <!-- 2.关联账号 -->
        <el-form-item label="关联账号">
          <!-- 单选框 -->
          <el-select
              v-model="form.userId"
              placeholder="请选择"
          >
            <el-option
                v-for="user in userList"
                :key="user.id"
                :label="user.username + ((user.remark) ? ' ('+user.remark+')' : '')"
                :value="parseInt(user.id)"
            ></el-option>
          </el-select>
          <el-button type="text" size="small" style="margin-left: 20px" @click="dialogVisible = true">新增账号</el-button>
        </el-form-item>

        <!-- 3.支部介绍 -->
        <el-form-item label="支部介绍">
          <el-input type="textarea" v-model="form.partyBranchDescribe" placeholder="请输入内容" :rows="6"></el-input>
        </el-form-item>

        <el-form-item label="支部介绍图片">
          <!-- <m-uploader type="single" :file.sync="form.coverImage"></m-uploader> -->
          <m-uploader :fileList.sync="imageList2"></m-uploader>
        </el-form-item>

         <!-- 5.党建风貌 -->
         <el-form-item label="党建风貌">
          <el-input type="textarea" v-model="form.partyStyle" placeholder="请输入内容" :rows="6"></el-input>
        </el-form-item>

        <el-form-item label="党建风貌图片">
          <m-uploader :fileList.sync="imageList"></m-uploader>
        </el-form-item>
        <el-form-item label="荣誉介绍" v-if="form.honors.length>0">
          <el-input type="textarea" v-model="form.honorDescribe" placeholder="请输入内容" :rows="6" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <div v-for="(honor, index) in form.honors" :key="index">
          <el-form-item :label="'荣誉' + (index+1)">
            <el-input placeholder="请输入荣誉名称" v-model="honor.honorName"></el-input>
          </el-form-item>
          <el-form-item>
            <m-uploader type="single" :file.sync="form.honors[index].imageUrl"></m-uploader>
            <i class="el-icon-delete" @click="deleteHonor(index)" style="font-size: 22px;"></i>
        </el-form-item>
        </div>
        <el-form-item label="荣誉">
          <i class="el-icon-circle-plus-outline" @click="addHonor" style="font-size: 22px;"></i>
        </el-form-item>
      </el-form>

    </el-card>
    <div style="text-align:center">
      <div>&emsp;</div>
      <el-button @click="$router.push('./partylist')">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </div>
  </div>
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    width="30%">
    <add-user source="2"  @save="save" @cancel="cancel"></add-user>
  </el-dialog>
  </section>
</template>

<script>

import Admin from "@/axios/api/admin";
import PartyBranch, {createPartyBranch} from "@/axios/api/partyBranch";
import Info from "@/axios/api/info";
import addUser from "@/pages/user/edit.vue"

export default {
  name: "product-selection-pool-edit",
  components: {
    addUser
  },

  data() {
    return {
      objId: null,
      dialogVisible: false,
      form: {
        userId:"",
        name: '',
        partyId: '',
        partyBranchDescribe: '',
        honorDescribe: "",
        coverImages: [],
        partyStyle:  '',
        images: [],
        honors: [],
      },
      userList: null,
      imageList: [],  //党建风貌图片
      imageList2: [], //支部介绍图片
      showUserModal: false,
    }
  },

  created() {
    let query = this.$route.query
    if (query.id) {
      this.objId = query.id
      this.getOne(this.objId )
    } else {
      this.getUserList()
    }
  },

  methods: {
    //添加荣誉
    addHonor() {
      this.form.honors.push({
        honorName: '',
        imageUrl: ''
      })
    },
    deleteHonor(index) {
      this.form.honors.splice(index,1)
    },
    /** 获取用户列表 */
    async getUserList(userId) {
      let res = {}
      if(userId) {
        res = await Admin.getUserList({userId})
      } else {
        res = await Admin.getUserList({userId: -1})
      }
      if(res.data.status ==1) {
        this.userList = res.data.data
      }
    },

    /** 上传封面图 */
    uploadCoverImg(res) {
      this.form.coverImageUrl = res.data;
      this.$forceUpdate();
    },
    uploadImg(res) {
      this.obj.imageUrl = res.data;
      this.$forceUpdate();
    },

    save() {
      this.dialogVisible = false;
      this.getUserList(this.form.userId)
    },
    // 保存
    onSubmit() {
      this.form.honors = this.form.honors.filter((item) => {
        return item.honorName || item.imageUrl
      })
      if(this.form.honors.length<=0) {
        this.form.honorDescribe=''
      }
      if (this.isValid(this.form)) {
        if (!this.objId) {
          // 新建
          var createData = this.getData();
          PartyBranch.createPartyBranch(createData).then(res => {
            console.log(res);
            if(res.data.status == 1) {
              this.$message.success("新增成功");
              this.$router.push("./partylist");
            } else {
              this.$message.error("新增失败");
            }
          })
        } else {
          // 编辑
          var createData = this.getData();
          createData.id = this.objId
          PartyBranch.updatePartyBranch(createData).then(res => {
            if(res.data.status == 1) {
              this.$message.success("编辑成功");
              this.$router.push("./partylist");
            } else {
              this.$message.error("新增失败");
            }
          })
        }
      }
    },

    /** 获取党支部详情 */
    async getOne(id) {
      let res = await PartyBranch.getBranchDetail({id})
      console.log(res);
      if (res.data.status == 1) {
        this.form = res.data.data
        this.imageList = this.form.images.map(item => {
          return {
            url:item.imageUrl
          }
        })
        this.imageList2 = this.form.coverImages.map(item => {
          return {
            url:item.imageUrl
          }
        })
        this.getUserList(this.form.userId)
      }
    },

    isValid(obj) {
      if (!obj.name) {
        this.$message.info("支部名称不能为空");
        return false;
      }
      if (!obj.userId) {
        this.$message.info("关联账号不能为空");
        return false;
      }
      if (!obj.partyBranchDescribe) {
        this.$message.info("支部介绍不能为空");
        return false;
      }
      // if (!obj.coverImage) {
      //   this.$message.info("封面图不能为空");
      //   return false;
      // }
      if (this.imageList2.length == 0) {
        this.$message.info("支部介绍图片不能为空");
        return false;
      }
      if (!obj.partyStyle) {
        this.$message.info("党建风貌不能为空");
        return false;
      }
      if (this.imageList.length == 0) {
        this.$message.info("党建风貌图片不能为空");
        return false;
      }
      for(let i=0;i<obj.honors.length;i++){
        if(!obj.honors[i].imageUrl) {
          this.$message.info("荣誉"+i+1 +"图片不能为空");
          return false;
        } else if(!obj.honors[i].honorName) {
          this.$message.info("荣誉"+(i+1) +"名称不能为空");
          return false;
        }
      }
      return true;
    },

    /** 新建的数据 */
    getData() {
      let data = {
        "userId": this.form.userId,
        "partyBranchDescribe": this.form.partyBranchDescribe,
        "iconUrl": this.form.iconUrl,
        "coverImages": this.imageList2.map((item,index) => {
          return {
            imageUrl:item.url,
            seq: index
          }
        }),
        "partyStyle": this.form.partyStyle,
        "name": this.form.name,
        "honors": this.form.honors,
        "images": this.imageList.map((item,index) => {
          return {
            imageUrl:item.url,
            seq: index
          }
        }),
        "honorDescribe": this.form.honorDescribe
      }
      console.log("提交的数据");
      console.log(data);
      return data;
    },
    cancel() {
      this.dialogVisible = false
    }

  }
}
</script>

<style scoped>
.photo-img-row {
  flex-wrap: wrap;
}

.photo-img {
  width: 200px;
  height: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
  object-fit: cover;
}

.photo-img__delete {
  font-size: 32px;
  position: absolute;
  right: 15px;
  top: 0;
  color: rgba(255, 0, 0, 0.8);
}
</style>

<style scoped>
.uploader {
  width: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploader.sub {
  width: 150px;
}

.uploader:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  line-height: 150px;
  text-align: center;
}

.uploader-icon.sub {
  width: 150px;
  line-height: 150px;
}




</style>
<!-- 我新增的 -->
<style scoped lang="less">
/deep/.el-card__body .el-form-item__label {
  width: 70px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

}

.upload_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 88px;
  box-sizing: border-box;
  width: 88px;
  .text{
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 8px;
  }
  .el-icon-plus {
    font-size: 20px;
    color: #707070;
  }
  .subImg {
    width: 88px;
    height: 88px;
    vertical-align: middle;
  }
}

.mian_cell {
  display: flex;
  align-items: flex-start;
  .m-cardcell {
    background-color: #fff;
    flex: 1;
  }
  .right {
    width: 308px;
    height: 254px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    margin-left: 10px;
  }
}

</style>